<template>
  <div class="content">
    <app-cells position="between">
      <app-cells position="start">
        <router-link
          :to="{ name: 'event-detail' }"
          class="btn btn--link"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <div class="title title--big title--theme">Список заявок ({{ dir_name }})</div>
      </app-cells>
      <app-button v-if="table.items.length > 0" @click="onDownloadExcel" type="button" ref="download" size="link">
        <img src="@/assets/img/download-icon.svg" alt="">
        Скачать заявки в Excel
      </app-button>
    </app-cells>
    <app-cells position="between">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown
            class="my-dropdown-toggle"
            :options="limitOptions"
            :selected="limitSelected"
            :closeOnOutsideClick="true"
            @updateOption="updateLimiter"
          />
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </app-cells>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps1"
          type="radio"
          name="apps"
          value="pending"
          v-model="status"
          checked
        >
        <label for="apps1" class="filters__item">На рассмотрении ({{ pending }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps2"
          type="radio"
          name="apps"
          value="accepted"
          v-model="status"
        >
        <label for="apps2" class="filters__item">Принято ({{ accepted }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps3"
          type="radio"
          name="apps"
          value="rejected"
          v-model="status"
        >
        <label for="apps3" class="filters__item">Отклонено ({{ rejected }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps4"
          type="radio"
          name="apps"
          value="reserve"
          v-model="status"
        >
        <label for="apps4" class="filters__item">Резерв ({{ reserve }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps5"
          type="radio"
          name="apps"
          value="rework"
          v-model="status"
        >
        <label for="apps5" class="filters__item">На доработке ({{ rework }})</label>
        <input
          @change="fetchItems(1, limitSelected.name, status)"
          id="apps6"
          type="radio"
          name="apps"
          value=""
          v-model="status"
        >
        <label for="apps6" class="filters__item">Все</label>
      </div>
      <div class="filters__search">
        <v-select
          @search="onSearch"
          noDrop
          placeholder="ID заявки / ФИО"
          label="id"
          class="select"
        >
        </v-select>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default table-default--dynamic table-default--minw"
    >
      <div slot="is_command" slot-scope="props">
        {{ props.row.is_command ? 'командная' : 'индивидуальная' }}
      </div>
      <div slot="user.full_name" slot-scope="props">
        {{ props.row.is_command ? props.row.team_info.team.name : `${props.row.user.full_name} (ID: ${props.row.user.id})` }}
      </div>
      <div slot="updated_at" slot-scope="props">
        {{ props.row.updated_at || '-' }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'
import { debounce } from 'lodash'
import { format } from 'date-fns'

export default {
  name: 'ApplicationsList',
  components: {
    dropdown,
  },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'is_command', 'user.full_name', 'created_at', 'updated_at'],
        options: {
          headings: {
            'id': 'Заявка',
            'is_command': 'Тип',
            'user.full_name': 'ФИО / Название команды',
            'created_at': 'Создана',
            'updated_at': 'Обновлена',
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: 20},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
      status: '',
      pending: 0,
      rejected: 0,
      accepted: 0,
      reserve: 0,
      rework: 0,
      once: false,
      dir_name: ''
    }
  },
  mounted() {
    this.limitSelected.name = 20
    this.fetchItems(1, this.limitSelected.name)
    this.$store.dispatch('events/directions/GET_DATA', { id: this.$route.params.id, dir: this.$route.params.dir })
      .then(response => { this.dir_name = response.data.name })
  },
  methods: {
    fetchItems(page = 1, page_size, status = '', search = '') {
      this.pagination.page = page
      this.$store.dispatch('events/applications/GET_LIST', { event_id: this.$route.params.id, shift_id: this.$route.params.dir, page, page_size, status, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
          if (!this.once) {
            this.$store.dispatch('events/applications/GET_COUNTS', { event_id: this.$route.params.id, shift_id: this.$route.params.dir })
              .then(res => {
                this.pending = res.data.pending
                this.rejected = res.data.rejected
                this.accepted = res.data.accepted
                this.reserve = res.data.reserve
                this.rework = res.data.rework
              })
          }
          this.once = true
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'application-detail', params: { app: row.row.id } })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name, this.status)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name, this.status)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onSearch(search, loading) {
      loading(true)
      this.searchDelivery(loading, search, this)
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.limitSelected.name, vm.status, search)
      loading(false)
    }, 350),
    onDownloadExcel() {
      this.$refs.download.preload = true
      this.$store.dispatch('events/applications/GET_EXPORT', { id: this.$route.params.id, dir: this.$route.params.dir })
        .then(response => {
          this.$refs.download.preload = false
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Заявки_${this.dir_name}_${format(new Date(), 'dd.MM.yyyy hh_mm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            this.$refs.download.preload = false
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    },
  }
}
</script>
